$('.card-image').on('click', function() {
  let modal_id = $(this).data('id');

  let video = $('#modal-'+modal_id).find('video');
  $('#modal-'+modal_id).modal();
  video.trigger('play');

  $('#modal-'+modal_id).on('hidden.bs.modal', function (e) {
    video.trigger('pause');
  });
});

$('.baixar-apresentacao').on('click', function() {
  $('#modal-apresentacao').modal();
});

$('#embaixadores').on('click', function() {
  $('#modal-embaixadores').modal();
});